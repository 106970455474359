import Alpine from 'alpinejs'; window.Alpine = Alpine; Alpine.start();
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0ce7fcd7fc5ba4b740a0210925639e0f@o1078459.ingest.us.sentry.io/4508630262087680",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: "4143ed8c4074fbee56515dbb15be69361ebedebe",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});